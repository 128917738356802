import React, { useState } from 'react';
import Img from '../../images/logo.png';
import { Form, Modal, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Notifications, setLocalStorage } from '../../utils';
import { Config } from '../../config';
import axios from 'axios';

function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const config = Config();
  const navigate = useNavigate();
  const token = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')) : null;
  const headers = {
    headers: {
        Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  const handleSubmitPassword = (values) => {
    const { old_password, new_password, confirm_password } = values;

    if (new_password !== confirm_password) {
      Notifications('error', 'Error', 'New passwords do not match.');
      return;
    }

    setLoading(true);
    const url = `${config.base_url}change_password/`;
    
    axios
      .post(
        url,
        {
          old_password: old_password,
          new_password: new_password,
          confirm_password: confirm_password,
        },
        headers
      )
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          Notifications('success', 'Success', 'Password changed successfully');
          const existingUserInfo = JSON.parse(localStorage.getItem('userInfo'));
          setLocalStorage('userInfo', {
            ...existingUserInfo,
            isLoggedIn: true,
          });
          navigate('/');
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.data && error.response.data.errors) {
          const errorMessages = error.response.data.errors;
          for (const field in errorMessages) {
            if (errorMessages.hasOwnProperty(field)) {
              Notifications('error', 'Error', `${field}: ${errorMessages[field].join(' ')}`);
            }
          }
        } else {
          Notifications('error', 'Error', 'An error occurred while changing the password. NB You cannot use a password you once used before or value to similar to the email');
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    Notifications('error', 'Error', 'Please check the form and try again.');
  };

  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <a href="/" className="logo d-flex align-items-center w-auto">
                    <img src={Img} alt="logo" />
                  </a>
                </div>
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">Change Account Password</h5>
                      <p className="text-center small">Enter your old & new password to login</p>
                    </div>
                    <Spin spinning={loading}>
                      <Form
                        onFinish={handleSubmitPassword}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        className="row g-3 needs-validation"
                      >
                        <div className="col-12">
                          <Form.Item
                            name="old_password"
                            rules={[{ required: true, message: 'Old Password required!' }]}
                          >
                            <input
                              type="password"
                              name="old_password"
                              className="form-control"
                              placeholder="Old Password"
                            />
                          </Form.Item>
                        </div>

                        <div className="col-12">
                          <Form.Item
                            name="new_password"
                            rules={[{ required: true, message: 'New Password required!' }]}
                          >
                            <input
                              type="password"
                              name="new_password"
                              className="form-control"
                              placeholder="New Password"
                            />
                          </Form.Item>
                        </div>

                        <div className="col-12">
                          <Form.Item
                            name="confirm_password"
                            rules={[{ required: true, message: 'Confirm Password required!' }]}
                          >
                            <input
                              type="password"
                              name="confirm_password"
                              className="form-control"
                              placeholder="Confirm Password"
                            />
                          </Form.Item>
                        </div>

                        <div className="col-12">
                          <button
                            className="btn text-uppercase w-100 blue-gradient-bg-1 rounded-pill text-white fw-800 border-0 px-5"
                            type="submit"
                          >
                            Change Password
                          </button>
                        </div>
                      </Form>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default ChangePassword;
