import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Config } from '../../../config';
import axios from 'axios';
import CardFilter from './CardFilter';
import { Spin } from 'antd';

function ReportCharts() {
  const config = Config();
  const [statistics, setReports] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('Today');
  const token = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')) : null;

  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  useEffect(() => {
    getLoanReports();
  }, []);

  const handleFilterChange = (filter) => {
    setFilter(filter);
  };

  const getLoanReports = () => {
    setLoading(true)
    let url = `${config.base_url}loan_reports/`;
    axios
      .get(url, headers)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setLoading(false);
          setReports(res.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        setReports(null);
      });
  };

  const formatData = (data) => {
    return data.map(value => Number(value.toFixed(2)));
  };

  const getFilteredStatistics = () => {
    if (!statistics) return null;
    switch (filter) {
      case 'Today':
        return statistics.daily;
      case 'This Month':
        return statistics.monthly;
      case 'This Year':
        return statistics.yearly;
      default:
        return statistics.daily;
    }
  };

  const filteredStatistics = getFilteredStatistics();

  useEffect(() => {
    if (filteredStatistics) {
      setData({
        series: [
          {
            name: 'Civil Servant Loans',
            data: formatData(filteredStatistics.ssb_loans),
          },
          {
            name: 'Pensioner Loans',
            data: formatData(filteredStatistics.pensioner),
          },
        ],
        options: {
          chart: {
            height: 350,
            type: 'area',
            toolbar: {
              show: false,
            },
          },
          markers: {
            size: 4,
          },
          colors: [ '#ff771d', '#2eca6a'],
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.3,
              opacityTo: 0.4,
              stops: [0, 90, 100],
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          xaxis: {
            type: 'datetime',
            categories: filteredStatistics.categories,
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },
        },
      });
    }
  }, [filteredStatistics]);

  const [data, setData] = useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false,
        },
      },
      markers: {
        size: 4,
      },
      colors: [ '#ff771d', '#2eca6a'],
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.3,
          opacityTo: 0.4,
          stops: [0, 90, 100],
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        type: 'datetime',
        categories: [],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    },
  });

  return (
    <>
      <CardFilter filterChange={handleFilterChange} />
      <div className="card-body">
        {/* <Spin loading={loading}> */}
        <h5 className="card-title">
          Reports <span> | {filter}</span>
        </h5>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <Chart
            options={data.options}
            series={data.series}
            type={data.options.chart.type}
            height={data.options.chart.height}
          />
        )}
        {/* </Spin> */}
      </div>
    </>
  );
}

export default ReportCharts;
