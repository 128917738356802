import React, { useEffect, useState } from 'react';
import CardFilter from './CardFilter';
import axios from 'axios';
import { Config } from '../../../config';
import { Spin } from 'antd';

function Cards() {
  const config = Config();
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('Today');

  const handleFilterChange = (filter) => {
    setFilter(filter);
  };
  const token = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')) : null;
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  useEffect(() => {
    getLoanStatistics();
  }, []);

  const getLoanStatistics = () => {
    let url = `${config.base_url}loan_statistics/`;
    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setStatistics(res.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        setStatistics(null);
      });
  };

  const getFilteredStatistics = () => {
    if (!statistics) return null;
    switch (filter) {
      case 'Today':
        return statistics.daily;
      case 'This Month':
        return statistics.monthly;
      case 'This Year':
        return statistics.yearly;
      default:
        return statistics.daily;
    }
  };

  const filteredStatistics = getFilteredStatistics();

  const formatAmount = (amount) => {
    return amount ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount) : '0.00';
  };

  const renderPercentageChange = (percentageChange) => {
    if (!percentageChange) return null;
    const { increase, decrease } = percentageChange;
    if (increase > 0) {
      return (
        <>
          <span className="text-success small pt-1 fw-bold">{increase.toFixed(2)}%</span>{' '}
          <span className="text-muted small pt-2 ps-1">increase</span>
        </>
      );
    } else if (decrease > 0) {
      return (
        <>
          <span className="text-danger small pt-1 fw-bold">{Math.abs(decrease).toFixed(2)}%</span>{' '}
          <span className="text-muted small pt-2 ps-1">decrease</span>
        </>
      );
    } else {
      return (
        <>
          <span className="text-muted small">No change</span>
        </>
      );
    }
  };

  return (
    <>
      <div className="col-xxl-4 col-md-6">
        <Spin spinning={loading}>
          <div className="card info-card revenue-card">
            <CardFilter filterChange={handleFilterChange} />
            <div className="card-body">
              <h5 className="card-title">
                Completed Applications <span>| {filter} </span>
              </h5>
              {filteredStatistics ? (
                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                  <i className="bi bi-window-stack"></i>
                  </div>
                  <div className="ps-3">
                    <h6>{filteredStatistics.completed_applications}</h6>
                    {/* {filteredStatistics.percentage_change.completed_applications ? (
                      renderPercentageChange(filteredStatistics.percentage_change.completed_applications)
                    ) : (
                      <span className="text-muted small">No change</span>
                    )} */}
                  </div>
                </div>
              ) : (
                <div>Loading data...</div>
              )}
            </div>
          </div>
        </Spin>
      </div>

      <div className="col-xxl-4 col-md-6">
        <Spin spinning={loading}>
          <div className="card info-card sales-card">
            <CardFilter filterChange={handleFilterChange} />
            <div className="card-body">
              <h5 className="card-title">
                Pending Applications <span>| {filter} </span>
              </h5>
              {filteredStatistics ? (
                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                  <i className="bi bi-window"></i>
                  </div>
                  <div className="ps-3">
                    <h6>{filteredStatistics.pending_applications}</h6>
                    {/* {filteredStatistics.percentage_change.pending_applications ? (
                      renderPercentageChange(filteredStatistics.percentage_change.pending_applications)
                    ) : (
                      <span className="text-muted small">No change</span>
                    )} */}
                  </div>
                </div>
              ) : (
                <div>Loading data...</div>
              )}
            </div>
          </div>
        </Spin>
      </div>

      <div className="col-xxl-4 col-xl-12">
        <Spin spinning={loading}>
          <div className="card info-card customers-card">
            <CardFilter filterChange={handleFilterChange} />
            <div className="card-body">
              <h5 className="card-title">
                Chatbot Users <span>| {filter} </span>
              </h5>
              {filteredStatistics ? (
                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                  <i className="bi bi-person-bounding-box"></i>
                  </div>
                  <div className="ps-3">
                    <h6>{filteredStatistics.users}</h6>
                    {/* {filteredStatistics.percentage_change.users ? (
                      renderPercentageChange(filteredStatistics.percentage_change.users)
                    ) : (
                      <span className="text-muted small">No change</span>
                    )} */}
                  </div>
                </div>
              ) : (
                <div>Loading data...</div>
              )}
            </div>
          </div>
        </Spin>
      </div>
    </>
  );
}

export default Cards;
