import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Config } from '../../../config';

function RecentActivity() {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const config = Config();
  const token = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')) : null;
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  useEffect(() => {
    getPendingApplications();
  }, []);

  const getPendingApplications = () => {
    let url = `${config.base_url}pending_applications/`;
    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setActivities(res.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        setActivities([]);
        console.error('There was an error fetching the data:', error);
      });
  };

  const getStatusClass = (timeSinceCreated) => {
    if (timeSinceCreated.includes('min')) {
      return 'text-success'; 
    } else if (timeSinceCreated.includes('hour')) {
      return 'text-warning'; 
    } else {
      return 'text-danger'; 
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Recent Applications</h5>
        <div className="activity">
          {activities.length > 0 ? (
            activities.map((activity) => (
              <div className="activity-item d-flex" key={activity.id}>
                <div className="activite-label">{activity.time_since_created} ago</div>
                <i className={`bi bi-circle-fill activity-badge ${getStatusClass(activity.time_since_created)} align-self-start`}></i>
                <div className="activity-content">
                  User with ID {activity.id} is waiting to be approved
                </div>
              </div>
            ))
          ) : (
            <div>No recent activities found.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RecentActivity;
